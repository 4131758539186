import { render, staticRenderFns } from "./Badges.vue?vue&type=template&id=74221af0&scoped=true&"
import script from "./Badges.vue?vue&type=script&lang=ts&"
export * from "./Badges.vue?vue&type=script&lang=ts&"
import style0 from "./Badges.vue?vue&type=style&index=0&id=74221af0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74221af0",
  null
  
)

export default component.exports
import {QIcon} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QIcon})
