









































import { LastMessage } from '@/components/ChatsBar/types'
import { CallStatus } from '@/store/modules/root/types'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    Badge: () => import('@/components/TeamsBar/Badge.vue'),
    BaseCallStatusIcon: () => import('@/components/UI/BaseCallStatusIcon.vue'),
    IconClock: () => import('@tada/icons/dist/IconClock.vue'),
    IconPin: () => import('@tada/icons/dist/IconPin.vue'),
    IconServerCheck:
      () => import('@tada/icons/dist/IconServerCheck.vue'),
    IconServerSend:
      () => import('@tada/icons/dist/IconServerSend.vue'),
  },
})
export default class Badges extends Vue {
  @Prop({ type: String }) private readonly callStatus?: CallStatus
  @Prop({ type: Boolean }) private readonly isMuted?: boolean
  @Prop({ type: Boolean }) private readonly isPin?: boolean
  @Prop({ type: String }) private readonly messageStatus?: LastMessage['status']
  @Prop({ type: Boolean }) private readonly noticed?: boolean
  @Prop({ type: Number }) private readonly unread?: number
}
